<script lang="ts" setup>
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size?: Sizes;
			state?: "red-semi" | "red" | "grey" | "disabled" | "error";
			loading?: boolean;
			error?: boolean;
			warning?: boolean;
		}>(),
		{
			size: "small",
			state: "red",
			loading: false,
			error: false,
			warning: false,
		}
	);

	const emit = defineEmits<{
		(e: "click"): void;
	}>();

	const getArrowColor = computed(() => {
		if (props.state === "red-semi" || props.state === "grey") {
			return "black";
		} else {
			return "white";
		}
	});
</script>

<template>
	<button @click="emit('click')" type="button" class="button flex-horizontal" :class="[size, state, { warning: warning }, { error: error }, { loading: loading }]">
		<span><slot /></span>

		<spinner v-if="loading" :size="size" :color="getArrowColor" />
	</button>
</template>

<style scoped lang="scss">
	.button {
		user-select: none;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: fit-content;
		@include GoogleSans(false);
		@include FontWeightBold(false);
		transition: all 0.1s ease-out;

		span {
			transition: opacity 0.15s ease-out;
		}

		&.loading {
			span {
				opacity: 0;
			}
		}

		.spinner {
			position: absolute;
			@include FadeIn(0.3s);
		}

		&.small {
			height: 34px;
			border-radius: 17px;
			padding-left: 30px;
			padding-right: 30px;
			letter-spacing: -0.1px;
			font-size: 14px;
		}

		&.medium {
			height: 44px;
			border-radius: 22px;
			padding-left: 35px;
			padding-right: 35px;
			letter-spacing: -0.2px;
			font-size: 16px;
		}

		&.large {
			height: 52px;
			border-radius: 26px;
			padding-left: 40px;
			padding-right: 40px;
			letter-spacing: -0.2px;
			font-size: 18px;
		}

		&.red {
			background: $red;
			color: white;
		}

		&.red-semi {
			background: $red-semi;
			color: black;
		}
		&.disabled {
			background: $grey_semi;
			color: white;
			pointer-events: none;
		}

		&.error {
			background: $red-semi;
			color: $red;
			pointer-events: none;
		}
	}
</style>
